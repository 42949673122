import React, { useContext, useEffect, useRef } from "react"
import HelmetHead from "../../components/HelmetHead"
import LayoutContext from "../../components/LayoutContext"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import Curve3 from "../../svgs/shapes/curves/curve-3.svg"
import Curve5 from "../../svgs/shapes/curves/curve-5.svg"
import Loadable from "@loadable/component"
const Newsletter = Loadable(() => import("../../components/Newsletter"))
import { graphql, Link } from "gatsby"
import Image from "../../components/Image"
import BlogPostPreview from "../../components/BlogPostPreview"
import LoadGatsbyOptimizedImage from "../../components/LoadGatsbyOptimizedImage"

export const query = graphql`
  query {
    allStrapiBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "DD. MMMM, YYYY", locale: "de")
          content {
            data {
              content
            }
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const [layout, setLayout] = useContext(LayoutContext)

  const blogPosts = data.allStrapiBlogPost.edges

  const images = LoadGatsbyOptimizedImage()
  const img = "haus_dunkler3.jpg"
  const overlayImage = images["custom"].edges.find(
    ({ node }) =>
      node.childImageSharp &&
      node.childImageSharp.gatsbyImageData.images.fallback.src
        .split("/")
        .pop() === img
  ).node.childImageSharp.gatsbyImageData.images.fallback.src

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {},
    }))
  }, [])
  return (
    <>
      <HelmetHead title="Immobilien und Makler News - Blog der Maklerzentrale" />

      <section
        data-jarallax
        data-speed=".8"
        className="py-10 py-md-14 overlay bg-cover jarallax"
        style={{
          backgroundImage: `url(${overlayImage})`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7 text-center">
              <h1 className="display-2 fw-bold text-white text-shadow-custom">
                Blog der Maklerzentrale
              </h1>
              <p className="lead mb-0 text-white text-shadow-custom">
                Immobilien und Makler News
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <Curve1 />
        </div>
      </div>

      <section className="mt-n8">
        <div className="container">
          <div className="row">
            {blogPosts.slice(0, 1).map(({ node: blogPost }) => (
              <div className="col-12" key={blogPost.id}>
                <div className="card card-row shadow-light-lg mb-6 lift lift-lg">
                  <div className="row gx-0">
                    <div className="col-12">
                      <span className="badge rounded-pill bg-light badge-float badge-float-inside">
                        <span className="h6 text-uppercase">Aktuell</span>
                      </span>
                    </div>
                    <Link
                      className="col-12 col-md-6 order-md-2 bg-cover card-img-end"
                      style={{
                        backgroundImage: `url(${blogPost.preview_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,
                      }}
                      to={`/de/blog/${blogPost.slug}/`}
                    >
                      <img
                        src={
                          blogPost.preview_image.localFile.childImageSharp
                            .gatsbyImageData.images.fallback.src
                        }
                        alt={blogPost.title}
                        className="img-fluid d-md-none invisible"
                      />

                      <div className="shape shape-start shape-fluid-y text-white d-none d-md-block">
                        <Curve5 />
                      </div>
                    </Link>
                    <div className="col-12 col-md-6 order-md-1">
                      <Link
                        className="card-body pt-6 pb-4"
                        to={`/de/blog/${blogPost.slug}/`}
                      >
                        <h3
                          dangerouslySetInnerHTML={{ __html: blogPost.title }}
                        />

                        <p
                          className="mb-0 text-muted"
                          dangerouslySetInnerHTML={{
                            __html: blogPost.content.data.content.slice(0, 150).concat(
                              "..."
                            ),
                          }}
                        />
                      </Link>

                      <Link
                        className="card-meta"
                        to={`/de/blog/${blogPost.slug}/`}
                      >
                        <hr className="card-meta-divider" />

                        <div className="avatar avatar-sm me-2">
                          <Image
                            src="albino_cipolla_bg.jpg"
                            className="avatar-img rounded-circle"
                            alt="Albino Cipolla"
                          />
                        </div>

                        <h6 className="text-uppercase text-muted me-2 mb-0">
                          Albino Cipolla
                        </h6>

                        <p className="h6 text-uppercase text-muted mb-0 ms-auto">
                          <time dateTime={blogPost.createdAt}>
                            {blogPost.createdAt}
                          </time>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="pt-7">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-12 col-md">
              <h3 className="mb-0">Alle Beiträge</h3>
              {/*<p className="mb-0 text-muted">*/}
              {/*  Here’s what we've been up to recently.*/}
              {/*</p>*/}
            </div>
            <div className="col-12 col-md-auto">
              {/*<a*/}
              {/*  href="#!"*/}
              {/*  className="btn btn-sm btn-outline-gray-300 d-none d-md-inline"*/}
              {/*>*/}
              {/*  Alle Beiträge*/}
              {/*</a>*/}
            </div>
          </div>
          <div className="row">
            {blogPosts.slice(1, blogPosts.length).map(({ node: blogPost }) => (
              <div className="col-12 col-md-6 d-flex" key={blogPost.id}>
                <BlogPostPreview blogPost={blogPost} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*<section className="pt-7 pt-md-10">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-12">*/}
      {/*        <h3 className="mb-0">Popular Stories</h3>*/}

      {/*        <p className="mb-5 text-muted">*/}
      {/*          Here’s what’s big in the past week!*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-12 col-md-6 d-flex">*/}
      {/*        <div className="card mb-6 shadow-light-lg lift lift-lg">*/}
      {/*          <a className="card-img-top" href="#!">*/}
      {/*            <img*/}
      {/*              src="https://landkit.goodthemes.co/assets/img/photos/photo-28.jpg"*/}
      {/*              alt="..."*/}
      {/*              className="card-img-top"*/}
      {/*            />*/}

      {/*            <div className="position-relative">*/}
      {/*              <div className="shape shape-bottom shape-fluid-x text-white">*/}
      {/*                <Curve3 />*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </a>*/}

      {/*          <a className="card-body" href="#!">*/}
      {/*            <h3>Greece Is Having A Tech Renaissance.</h3>*/}

      {/*            <p className="mb-0 text-muted">*/}
      {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
      {/*              Duis nec condimentum quam. Fusce pellentesque faucibus lorem*/}
      {/*              at viverra. Integer at feugiat odio. In placerat euismod*/}
      {/*              risus proin erat purus.*/}
      {/*            </p>*/}
      {/*          </a>*/}

      {/*          <a className="card-meta mt-auto" href="#!">*/}
      {/*            <hr className="card-meta-divider" />*/}

      {/*            <div className="avatar avatar-sm me-2">*/}
      {/*              <img*/}
      {/*                src="https://landkit.goodthemes.co/assets/img/avatars/avatar-1.jpg"*/}
      {/*                alt="..."*/}
      {/*                className="avatar-img rounded-circle"*/}
      {/*              />*/}
      {/*            </div>*/}

      {/*            <h6 className="text-uppercase text-muted me-2 mb-0">*/}
      {/*              Ab Hadley*/}
      {/*            </h6>*/}

      {/*            <p className="h6 text-uppercase text-muted mb-0 ms-auto">*/}
      {/*              <time datetime="2019-05-02">May 02</time>*/}
      {/*            </p>*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-md-6 d-flex">*/}
      {/*        <div className="card mb-6 shadow-light-lg lift lift-lg">*/}
      {/*          <a className="card-img-top" href="#!">*/}
      {/*            <img*/}
      {/*              src="https://landkit.goodthemes.co/assets/img/photos/photo-29.jpg"*/}
      {/*              alt="..."*/}
      {/*              className="card-img-top"*/}
      {/*            />*/}

      {/*            <div className="position-relative">*/}
      {/*              <div className="shape shape-bottom shape-fluid-x text-white">*/}
      {/*                <Curve3 />*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </a>*/}

      {/*          <a className="card-body" href="#!">*/}
      {/*            <h3>Is Failing Actually Bad for Business?</h3>*/}

      {/*            <p className="mb-0 text-muted">*/}
      {/*              Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
      {/*              Duis nec condimentum quam. Fusce pellentesque faucibus lorem*/}
      {/*              at viverra. Integer at feugiat odio. In placerat euismod*/}
      {/*              risus proin erat purus.*/}
      {/*            </p>*/}
      {/*          </a>*/}

      {/*          <a className="card-meta mt-auto" href="#!">*/}
      {/*            <hr className="card-meta-divider" />*/}

      {/*            <div className="avatar avatar-sm me-2">*/}
      {/*              <img*/}
      {/*                src="https://landkit.goodthemes.co/assets/img/avatars/avatar-2.jpg"*/}
      {/*                alt="..."*/}
      {/*                className="avatar-img rounded-circle"*/}
      {/*              />*/}
      {/*            </div>*/}

      {/*            <h6 className="text-uppercase text-muted me-2 mb-0">*/}
      {/*              Adolfo Hess*/}
      {/*            </h6>*/}

      {/*            <p className="h6 text-uppercase text-muted mb-0 ms-auto">*/}
      {/*              <time datetime="2019-05-02">May 02</time>*/}
      {/*            </p>*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<section className="py-7 py-md-10">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row justify-content-center">*/}
      {/*      <div className="col-12 col-md-9 col-lg-8 col-xl-7">*/}
      {/*        <a*/}
      {/*          href="#!"*/}
      {/*          className="btn w-100 btn-outline-gray-300 d-flex align-items-center"*/}
      {/*        >*/}
      {/*          <span className="mx-auto">Load more</span>{" "}*/}
      {/*          <i className="fe fe-arrow-right" />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<div className="position-relative">*/}
      {/*  <div className="shape shape-bottom shape-fluid-x text-gray-200">*/}
      {/*    <Curve1 />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <section className="pt-6 pt-md-8">
        <div className="container" id="newsletter">
          <div className="pb-6 pb-md-8 border-bottom border-gray-300">
            <Newsletter />
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog
